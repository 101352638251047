@use "../imports/base";

$sorry-img: "images/sticker_sorry.png";

.reptile_content--errorSite {
  grid-column: 1 / 3;
  grid-row: 3 / 4;
}

.reptile_error {
  &__site {
    width: 100%;
    font-family: base.$sansFontFamily;
    float: right;
  }

  &__img {
    float: left;
    background-image: url($sorry-img);
    width: 135px;
    height: 135px;
    margin: 20px 15px 20px 48px;
  }

  &__headline {
    strong {
      word-break: break-all;
    }

    @include base.font-size(base.$font150);
    @include base.breakpoint-large {
      @include base.font-size(base.$font175);
    }

    margin-top: 0;
    padding-top: 44px;
  }

  &__text {
    width: 100%;
    padding: 0 8px;
    overflow: auto;
    background-color: #fff;

    @include base.breakpoint-large {
      padding: 0 16px;

      .find-zeroHits-harmonisation & {
        width: 100%;
      }
    }

    .find_searchContainer {
      display: block;
      position: relative;
      padding: 0;
      top: 0;
      left: 0;
      width: 100%;
      background-color: base.$white;

      @include base.breakpoint-large {
        flex: 1 1 0;
        margin-top: 16px;
        width: 50%;
      }
    }
  }

  &__advice {
    color: base.$grey300;
    margin: 20px 0;
  }

  &__paragraph {
    color: base.$grey300;
  }
}
